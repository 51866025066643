<template>
	<div>
		<div
			:id="mainNavIsHidden ? 'main-nav-hidden-profile-container' : 'profile-container'"
			class="encore-background"
		>
			<fieldset>
				<legend>
					{{ languageStrings.accountProfileFor }}
					<span v-if="playerState?.displayName?.length > 0"> {{ playerState.displayName }}</span>
				</legend>
				<p v-if="playerState.permissionsSuspended" class="suspended">
					{{ languageStrings.suspendedMessage }}
				</p>
				<ChangeDisplayName
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<ChangeUserEmail
					:playerState="playerState"
					:userProfile="userProfile"
					:systemSettings="systemSettings"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<VerifyUserPhone
					v-if="!playerState?.isUserVerified"
					:playerState="playerState"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<PersonalInfoBasicTransactions
					v-if="countryCode === 'MX'"
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<PersonalInfoConspicuousTransactions
					v-if="countryCode === 'MX'"
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
			</fieldset>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import ChangeDisplayName from "@/components/ChangeDisplayName";
import VerifyUserPhone from "@/components/VerifyUserPhone";
import ChangeUserEmail from "@/components/ChangeUserEmail";
import PersonalInfoBasicTransactions from "@/components/PersonalInfoBasicTransactions";
import PersonalInfoConspicuousTransactions from "@/components/PersonalInfoConspicuousTransactions";

export default {
	name: "UserProfile",
	components: {
		ChangeDisplayName,
		ChangeUserEmail,
		VerifyUserPhone,
		PersonalInfoBasicTransactions,
		PersonalInfoConspicuousTransactions,
	},
	props: {
		systemSettings: Object,
		playerState: Object,
		isMobile: Boolean,
		verificationCode: String,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		mainNavIsHidden: Boolean,
		casinoList: Array,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			casinoId: this.casinoList[0].id, // We're picking the first casino for MX since only that jurisdiction requires the "MorePersonalInfoNeeded"
			webPayOptions: {},
			availableOffers: [],
			inGameCurrencyInfo: {},
		};
	},
	created() {
		if (this.countryCode === "MX") this.getWebPayOptions();
	},
	methods: {
		async getWebPayOptions() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/options`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			// if (this.playerState.casinoId) params.set("casinoId", this.playerState.casinoId);

			params.set("casinoId", this.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.webPayOptions = dataJson;
				this.availableOffers = dataJson.availableOffers;
				this.inGameCurrencyInfo = dataJson.casino.currencyInfo;

				let _state = this.playerState;
				_state.restrictedNeedsMoreInfo =
					dataJson?.taxAmlRestrictions?.idCheckNeeded || dataJson?.taxAmlRestrictions?.morePersonalInfoNeeded;
				this.eventBus.emit("updatePlayerState", _state);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#profile-container {
	position: relative;
	top: 30px;
	display: flex;
	flex-direction: column;
	width: 75%;
	margin: auto auto 130px;
	padding-bottom: 100px;
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	/* box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%),
		inset -2px -2px 10px 2px rgb(0 0 0 / 30%); */
}

#main-nav-hidden-profile-container {
	top: 15vh;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 65%;
	margin: auto auto 130px;
	padding-bottom: 100px;
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
}

#main-nav-hidden {
	height: 1700px;
}

legend {
	font-weight: bold;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#profile-container {
		margin-bottom: 200px;
	}
}
</style>

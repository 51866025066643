<template>
	<div id="personal-info" class="center-items">
		<hr class="thicker-radius" />
		<div v-if="serverBusy" class="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<legend>{{ languageStrings.personalInfo }}</legend>
		<!-- <pre>{{ JSON.stringify(personalInfo, null, "\t") }}</pre> -->
		<!-- <label for="name-first">{{ languageStrings.firstName }}</label> -->
		<input
			id="name-first"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.firstName"
			v-model="nameFirst"
		/>
		<!-- <label for="name-last">{{ languageStrings.lastName }}</label> -->
		<input
			id="name-last"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.lastName"
			v-model="nameLast"
		/>
		<!-- <label for="birth-date">{{ languageStrings.birthDate }}</label> -->
		<input
			id="birth-date"
			class="extra-padding-text-fields"
			type="date"
			:placeholder="languageStrings.birthDate"
			v-model="birthDate"
		/>
		<!-- <label for="nationality">{{ languageStrings.nationality }}</label> -->
		<input
			id="nationality"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.nationality"
			v-model="nationality"
		/>
		<!-- <label for="address-country">{{ languageStrings.addressCountry }}</label> -->
		<input
			id="address-country"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressCountry"
			v-model="addressCountry"
		/>
		<!-- <label for="address-county">{{ languageStrings.addressCounty }}</label> -->
		<input
			id="address-county"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressCounty"
			v-model="addressCounty"
		/>
		<!-- <label for="address-zipcode">{{ languageStrings.zipcode }}</label> -->
		<input
			id="address-zipcode"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.zipcode"
			v-model="addressZipCode"
		/>
		<button class="new-encore-button btn extra-padding" @click="setPersonalInfo()">
			{{ languageStrings.savePersonalInfo }}
		</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			personalInfo: this?.userProfile?.personalInfo,
			nameFirst: "",
			nameLast: "",
			nameLast2: "",
			birthDate: new Date().toISOString().split("T")[0],
			nationality: "",
			emailAddress: "",
			emailVerificationStatus: "",
			addressCountry: "",
			addressCounty: "",
			addressZipCode: "",
		};
	},
	watch: {},
	created() {
		this.populateFields();
	},
	mounted() {},
	methods: {
		async setPersonalInfo() {
			this.serverBusy = true;
			this.busyText = "Updating Personal Info";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						birthDate: this.birthDate,
						nationality: this.nationality,
						nameFirst: this.nameFirst,
						nameLast: this.nameLast,
						addressCountry: this.addressCountry,
						addressCounty: this.addressCounty,
						addressZipCode: this.addressZipCode,
					},
				},
				requiredValidations: "Minimal, BasicTransactions",
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;
						if (this.countryCode == "MX") {
							if (err.field == "NameFirst") {
								this.status.message = `${this.languageStrings.firstName}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "NameLast") {
								this.status.message = `${this.languageStrings.lastName}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
								console.log(this.status.message);
							}
							if (err.field == "Nationality") {
								this.status.message = `${this.languageStrings.nationality}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressCountry") {
								this.status.message = `${this.languageStrings.addressCountry}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressCounty") {
								this.status.message = `${this.languageStrings.addressCounty}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressZipCode") {
								this.status.message = `${this.languageStrings.zipcode}` + ": ";
								this.status.message += this.handleErrorReason(err.reason);
							}
						} else {
							this.status.message = `${this.camelToTitleCase(err.field)}: ${this.camelToTitleCase(
								err.reason
							)}`;
						}

						this.eventBus.emit("updateStatus", this.status);
					});
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = dataJson.status;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.userProfile = dataJson.userProfile;
				this.personalInfo = dataJson?.userProfile?.personalInfo;

				this.status.ok = true;
				this.status.message = "Personal Info Updated";
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.populateFields();
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		populateFields() {
			this.nameFirst = this?.personalInfo?.nameFirst;
			this.nameLast = this?.personalInfo?.nameLast;
			// this.nameLast2 = this?.personalInfo?.nameLast2;
			this.birthDate = new Date(this?.personalInfo?.birthDate).toISOString().split("T")[0];
			this.nationality = this?.personalInfo?.nationality;
			this.emailAddress = this?.personalInfo?.emailAddress;
			// this.emailVerificationStatus = this?.personalInfo?.emailVerificationStatus;
			this.addressCountry = this?.personalInfo?.addressCountry;
			this.addressCounty = this?.personalInfo?.addressCounty;
			this.addressZipCode = this?.personalInfo?.addressZipCode;
		},

		handleErrorReason(reason) {
			let reasonTranslation = "";
			if (reason == "MayNotBeBlank") {
				reasonTranslation = this.languageErrorStrings.mayNotBeBlank;
				return reasonTranslation;
			}
			if (reason == "MustBeTwoLetters") {
				reasonTranslation = this.languageErrorStrings.mustBeTwoLetters;
				return reasonTranslation;
			}
			if (reason == "InvalidEmailAddress") {
				reasonTranslation = this.languageErrorStrings.invalidEmailAddress;
				return reasonTranslation;
			}
			if (reason == "ValueOutOfRange") {
				reasonTranslation = this.languageErrorStrings.valueOutOfRange;
				return reasonTranslation;
			}
			if (reason == "ContainsInvalidCharacters") {
				reasonTranslation = this.languageErrorStrings.containsInvalidCharacters;
				return reasonTranslation;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	display: grid;
	align-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

#personal-info {
	display: flex;
	flex-flow: column;
}

.thicker-radius {
	width: 100%;
}

input {
	width: 50%;
	border-radius: 15px;
	height: 25px;
	color: #6305c9;
	border: transparent;
}

input[type="date"]::-webkit-input-placeholder {
	opacity: 50% !important;
}

@media (max-width: 768px) {
	input {
		width: 75% !important;
	}
}
</style>
